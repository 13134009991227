import Toaster from "@meforma/vue-toaster";
import { createApp } from "vue";
import App from "./App.vue";
import "./index.css";
import router from "./router";
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'

const app = createApp(App);

app
  .use(Autocomplete)
  .use(Toaster)
  .use(router)
  .mount("#app");